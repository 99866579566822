import { LOAD_FINISH, LOAD_GROUPCOLOR, LOAD_MATERIAL, LOAD_MODULE, LOAD_PRODUCT, LOAD_PROFILE, LOAD_ROLE, LOAD_STYLE, LOAD_USER } from "../actions/types";

const initialState = {
  user: null,
  role: null,
  module: null,
  material: null,
  groupColor: null,
  profile: null,
  style: null,
  finish: null,
  product: null,
};

export default function master(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_USER:
      return { ...state, user: payload.data };
    case LOAD_ROLE:
      return { ...state, role: payload.data };
    case LOAD_MODULE:
      return { ...state, module: payload.data };
    case LOAD_MATERIAL:
      return { ...state, material: payload.data };
    case LOAD_GROUPCOLOR:
      return { ...state, groupColor: payload.data };
    case LOAD_PROFILE:
      return { ...state, profile: payload.data };
    case LOAD_STYLE:
      return { ...state, style: payload.data };
    case LOAD_FINISH:
      return { ...state, finish: payload.data };
    case LOAD_PRODUCT:
      return { ...state, product: payload.data };
    default:
      return state;
  }
}
